$veg: #0abf53;
$non-veg: #ff0b00;

$body-bg: #f6f6f6;

$font-family-sans-serif: "Roboto", sans-serif;

@import "~bootstrap/scss/bootstrap";

@each $color, $value in $theme-colors {
  .alert-#{$color},
  .btn-#{$color}:not(.btn-sm):not(:disabled) {
    box-shadow: 0 5px 10px transparentize($value, .75);
  }
}

.alert {
  border-width: 0;
  border-left-width: 4px;
  border-right-width: 4px;
}

.border-transparent {
  border-color: transparent !important;
}

.item-layout {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: repeat(1, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
}

.item-cell {
  page-break-inside: avoid;
  break-inside: avoid;
  position: relative;
}

.item-cell .card-text,
.menu-item-wrapper .card-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.veg {
  display: inline-block;

  &-badge {
    position: absolute;
    right: .5em;
    top: .5em;
  }

  &-box {
    align-items: center;
    background: white;
    border: 1px solid;
    border-radius: 2px;
    display: flex;
    flex-shrink: 0;
    height: 1em;
    justify-content: center;
    width: 1em;

    &-v {
      border-color:  $veg;
    }

    &-nv {
      border-color:  $non-veg;
    }
  }

  &-dot {
    border-radius: 50%;
    height: .5rem;
    width: .5rem;

    &-v {
      background: $veg;
    }

    &-nv {
      background: $non-veg;
    }
  }
}
